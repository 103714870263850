import React from 'react'
import { useSiteMetadata } from '../components/SiteData'
import AppLayout from "../components/AppLayout";
import RouterButton from '../components/shared/RouterButton'
import AnalogClock from '../components/AnalogClock'
import PageInfo from '../components/PageInfo'
import SignUpAndDemoButtons from '../components/SignUpAndDemoButtons'
import Brand from '../components/shared/Brand'
import Spacing from '../reused-libraries/Spacing'
import { FeatureHeader } from '../components/FeatureDisplay'
import Grid from '@material-ui/core/Grid'
import AnchorLink from '../components/shared/AnchorLink'
import RouterLink from '../components/shared/RouterLink'

export default function About(props) {
	const {productName} = useSiteMetadata();
	return (
		<AppLayout>
			<PageInfo
				title={"About " + productName}
				description={productName + " time tracker was created to be robust and affordable in a market where we had perceived a lack of suitable time management offerings."}
			/>

			<div className="tt-screen-content">
				<div className="container-fluid px-sm-5 mb-5">
					<Grid container spacing={2}>
						<Grid item xs={12} md className="mb-4">

							<section className="mb-5">
								<FeatureHeader className="mb-4">About <Brand/> Time Tracker</FeatureHeader>

								<p>
									<Brand/> is an affordable
									{' '}
									<strong>
										<RouterLink to="/features/">time</RouterLink> and
										{' '}<RouterLink to="/features/expenses/">expense</RouterLink>
										{' '}tracking system
									</strong>
									{' '}enhanced with
									{' '}<strong><RouterLink to="/features/invoicing/">automatic invoicing</RouterLink></strong>.
									That means that you don't
									have to create your own invoices. Configure how often you need to
									invoice your clients, and we'll notify you each time they are ready!
								</p>
								<p>
									Quickly clock in as you start working, or enter your time
									retroactively. View your entire team's time and expenses, and grant
									access to edit entries for other teammates. When each period ends,
									send off an invoice!
								</p>
								<p>
									Before creating <Brand/>, we
									found competing expense and time tracking systems to be priced
									somewhat high. We reasoned that we could develop a quality system
									while remaining a bit more affordable.
								</p>
								<p>
									Whether you are tracking time and expenses as a team or individually,
									{' '}<Brand/> is built for a
									simple user experience.
								</p>
							</section>

							<section className="mb-4">
								<FeatureHeader component="h2" className="mb-4">About Us</FeatureHeader>

								<p>
									We are <AnchorLink href="https://moment-software.com/about/">Moment Software LLC</AnchorLink>.
									{' '}Formed in 2011, we've been in the Dallas area since. We're focused
									on building <strong>quality software</strong>. Software robustness
									and high availability of systems are incredibly important to us. We
									expect our products to be intuitive, useful, secure, and accessible,
									all while remaining easy to use. Starting as a small business
									ourselves, we aim to cater to businesses both small or large.
								</p>
								<p>
									As we started our roles as independent contractors, we knew that we
									needed to keep records of time worked for clients of Moment Software
									LLC. So we began evaluating various existing time tracking apps. We
									were looking for a time tracker that was reliable and robust, yet
									wasn't going to cost too much.
								</p>
								<p>
									In the end, we decided that we would build <Brand/>.
									It proved to be an excellent tool for us to use as independent
									contractors. And now it is available for you to use as well!
								</p>
							</section>

						</Grid>

						<Grid item xs={12} md={5} className="mb-4">
							<FeatureHeader component="div" className="mb-4 d-none d-md-block">&nbsp;</FeatureHeader>
							<div className="mx-auto px-2" style={{maxWidth: "16rem"}}><AnalogClock/></div>
						</Grid>
					</Grid>
				</div>
			</div>
		</AppLayout>
	);
}
