import React from 'react';

import PropTypes from 'prop-types';
import * as d3Shape from 'd3-shape';

class AnalogClock extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			hour: 0,
			minute: 0,
			second: 0,
		};
		this.checkTime = this.checkTime.bind(this);
	}

	componentDidMount() {
		this.timerID = setInterval(this.checkTime, 1000);
		this.checkTime();
	}

	componentWillUnmount() {
		clearInterval(this.timerID);
	}

	checkTime() {
		const now = new Date();
		const hour = now.getHours();
		this.setState({
			hour: hour === 0 ? 12 : hour,
			minute: now.getMinutes(),
			second: now.getSeconds(),
		});
	}

	render() {
		let {invertedColors, backgroundColor = "rgba(0,0,0,0.0)"} = this.props;
		let {hour, minute, second} = this.state;

		const viewSize = 1000;
		const clockRadius = 500;
		const clockBorderStroke = 20;
		const fontSize = 100;

		let maxFaceRad = clockRadius - clockBorderStroke;
		let borderArc = d3Shape.arc()
			.innerRadius(0)
			.outerRadius(clockRadius - clockBorderStroke / 2)
			.startAngle(0)
			.endAngle(Math.PI * 2);

		let hourTransforms = [];
		for (let i = 1; i <= 12; i++) {
			let tx = clockRadius * 0.78 * Math.cos((i / 6 - 0.5) * Math.PI);
			let ty = clockRadius * 0.78 * Math.sin((i / 6 - 0.5) * Math.PI) + fontSize * 3/8;
			if (i >= 10) {
				// Since Arial font gives the extra "1" a little too much width
				tx -= fontSize * 0.12;
			}
			hourTransforms[i] = "translate(" + tx + "," + ty + ")";
		}

		const secondHandColor = "#ff0000";
		let borderColor = "rgba(80,80,80,1.0)";
		let numberColor = "#000000";
		let hourMinuteHandColor = "rgba(0,0,0,1.0)";
		if (invertedColors) {
			borderColor = "rgba(248,249,250,1.0)";
			numberColor = "rgba(248,249,250,1.0)";
			hourMinuteHandColor = "rgba(248,249,250,1.0)";
		}

		return (
			<svg viewBox={(-viewSize/2) + " " + (-viewSize/2) + " " + viewSize + " " + viewSize}>
				<g>
					<circle cx="0" cy="0" r={maxFaceRad} fill={backgroundColor} />
					<path d={borderArc()} fill="none" stroke={borderColor} strokeWidth={clockBorderStroke} />
				</g>
				{hourTransforms.map((transform, hour) => (
					<g key={hour} transform={transform}>
						<text textAnchor="middle" fontSize={fontSize} fontFamily="Arial" fill={numberColor}>{hour}</text>
					</g>
				))}
				<ClockHandPath radius={maxFaceRad * 0.6} fill={hourMinuteHandColor} thickness={1/72} radians={(hour + (minute / 60)) / 6 * Math.PI}/>
				<ClockHandPath radius={maxFaceRad * 0.95} fill={hourMinuteHandColor} thickness={1/72} radians={(minute + (second / 60)) / 30 * Math.PI}/>
				<ClockHandPath radius={maxFaceRad * 0.95} fill={secondHandColor} thickness={1/144} radians={second / 30 * Math.PI}/>
			</svg>
		);
	}
}
AnalogClock.propTypes = {
	/** true to do a dark clock */
	invertedColors: PropTypes.bool,
	/** The optional background color if you don't want it to be transparent, such as "rgba(255,255,255,0.8)" or "#ffffff" */
	backgroundColor: PropTypes.string,
};

function ClockHandPath(props) {
	let {radius, radians, fill, thickness} = props;

	let arcRadians = Math.PI * 2 * thickness;
	let arc = d3Shape.arc()
		.innerRadius(radius * 0.2)
		.outerRadius(radius * 1.3) // let the hand stick out a little from the center
		.startAngle(-arcRadians / 2)
		.endAngle(arcRadians / 2);

	let transform = "rotate(" + (180 + radians / Math.PI * 180) + " 0 0) translate(0," + (radius * 1.2) + ")";
	return (
		<g transform={transform}>
			<path d={arc()} fill={fill} stroke="none" />
		</g>
	);
}

export default AnalogClock;
